
<template>
  <div class="errorpage bgcolor">
      <Head  :show="true" :bg="true" />
      <div class="errorpage-main">
          <img @click="gotoIndex()" :src="require('@/assets/error/logo.png')" class="error-logo" alt="">
          <img class="error-img" :src="require('@/assets/error/403.png')" alt="">
      </div>
  </div>
</template>

<script>
    export default {
        methods:{
            gotoIndex(){
                this.$router.push({
                    name:"main-homeIndex"
                })
            }
        }
    }
</script>
